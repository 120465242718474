import React from "react";

import { Navigate, Route, Routes } from "react-router";
import { isBuyStarsCom } from "../constants/htmlStrings";
import {
  Home,
  AboutUs,
  TermsOfService,
  PrivacyPolicy,
  LP1,
  LP2,
  LP3,
  LP4,
  LP5,
  Kannada,
  Tamil,
  SpecialBonus,
  LP6,
  LP7
} from "../pages";

import ResponsibleGaming from "../pages/ResponsibleGaming";

const RootRouter = () => {

  const homeElement = isBuyStarsCom ? <Home /> : <Home />;

  return (
    <Routes>
      <Route path="/" element={homeElement} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/LP1" element={<LP1 />} />
      <Route path="/LP2" element={<LP2 />} />
      <Route path="/LP3" element={<LP3 />} />
      <Route path="/lp4" element={<LP4 />} />
      <Route path="/lp5" element={<LP5 />} />
      <Route path="/lp6" element={<LP6 />} />
      <Route path="/lp7" element={<LP7 />} />
      <Route path="/kannada" element={<Kannada />} />
      <Route path="/tamil" element={<Tamil />} />
      <Route path="/upgrade" element={<SpecialBonus />} />
      <Route path="/responsible-gaming" element={<ResponsibleGaming />} />
      <Route path="*" element={<Navigate to={"/LP3"} />} />
    </Routes>
  );
};

export default RootRouter;
