/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import logos from "../assets/images/new-logos.png";
import mobilePlayers from "../assets/images/new-mobile-DK.webp";
import specialDownloadButton from "../assets/images/specialDownloadButton.webp";
import "../styles/home-styles.css";
import { googleAnalyticsTrack } from "../utility/common";

const SpecialBonus = () => {
  const handleDownloadButtonClick = () => {
    googleAnalyticsTrack(true);
  };

  useEffect(() => {
    googleAnalyticsTrack();
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="container-lp3 special-container">
          <div className="sub-container special-sub-container">
          <div className="sub-container">
            <img alt="logo" src={logos} className="logos-lp1" />
            <div className="title-and-button-lp1">
              <div className="mobile-title-lp1">
              <div className="top-text-lp3">WE'VE UPGRADED TO GIVE YOU THE BEST GAMING EXPERIENCE!</div>
                <div className="bottom-text-lp3">Play Fantasy and other exciting games</div>
              </div>
              <div className="desktop-title-lp1">
                <div className="top-text-lp1">
                  Buy and trade players,
                  <br />
                  create teams to join contests,
                </div>
                <div className="bottom-text-lp1">Win Lakhs!</div>
              </div>
            </div>
            <img src={mobilePlayers} alt="" className="mobile-players-lp1" />
            {/* <QRCodeLP1 /> */}
          </div>

            <a
              href={"https://s3.ap-south-1.amazonaws.com/cdn.buystars.com/APK/TradingAndFantasy/BuystarsProTF-Upgrade.apk"}
              download
              onClick={handleDownloadButtonClick}
            >
              <div>
                <img
                  src={specialDownloadButton}
                  alt=""
                  className="special-button"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialBonus;
